import { useNavigate, useLocation } from "react-router-dom";


function Home() {
	const navigate = useNavigate();

	const search = useLocation().search;
	const ids = new URLSearchParams(search).get('ids');
	const token = new URLSearchParams(search).get('token');
	const isAdmin = new URLSearchParams(search).get('isAdmin');
	const documentType = new URLSearchParams(search).get('documentType')

  	function generateProgTemplatePrint(idTemplate) {
    	navigate({
			pathname:"/pdf",
			search:'?locale=fr_FR&ids=' + ids + '&token=' + token + '&isAdmin=' + isAdmin + "&template=" + idTemplate
		});
  	}

	  function openOnNewTabCraftMyPDF() {
		window.open('./pdf?locale=fr_FR&ids=' + ids + '&token=' + token)
	}

	
	return (
		<div style={styleHomePage.centerContainer}>
			<div style={styleHomePage.titleContainer}>
				<img alt="VCS" widht="150" height="120" src="Logo_VCS_2018.jpg"/>
				<h1>Sélection Template Print VCS</h1>
			</div>
			<div style={styleHomePage.templateSelectionContainer}>
				<div style={styleHomePage.templateSelectionCol}>
					
					<h2 style={styleHomePage.titleContainer}>Individuel</h2>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("f3777b238d7002f4")}>
						Séjour ski tout compris FR
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("78a77b230e0faa84")}>
						Séjour été tout compris (montagne)
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("57077b230db6cb24")}>
						Séjour ski tout compris US
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("24d77b230eadf7cc")}>
						Séjour bien-être tout compris
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("13777b230db5fcc8")}>
						Séjour tout compris été (littoral)
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("52677b23492dde9c")}>
						Séjour tout compris hiver (littoral)
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("aff77b23017ea428")}>
						Séjour liberté (littoral)
						</button>
					</div>

					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("89577b2349244af6")}>
						Séjour locatif
						</button>
					</div>
				</div>
				<div style={styleHomePage.templateSelectionCol}>
					<h2 style={styleHomePage.titleContainer}>Groupe</h2>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("51f77b230e05301c")}>
						Séjour piéton tout compris
						</button>
					</div>

					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("95777b230e0e6bf8")}>
						Séjour excursions groupes
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("a3b77b230e061f0a")}>
						Séjour randonnées montagne groupes
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("4c877b2343265a22")}>
						Séjour randonnées littoral groupes
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("b5977b230ea151c4")}>
						Séjour Raquettes tout compris
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("2ec77b230ea5ff2c")}>
						Séjour Ski tout compris
						</button>
					</div>
					<div style={styleHomePage.btnContainer}>
						<button style={styleHomePage.templateBtn} type="button" onClick={() => generateProgTemplatePrint("90477b230e03f850")}>
						Court séjour ski tout compris
						</button>
					</div>
				</div>

			</div>
			
			
			
		</div>
	)
}

const styleHomePage = {
	templateBtn : {
		borderRadius:5,
		paddingTop: 5,
		paddingBottom: 5,
		paddingLeft: 10,
		paddingRight: 10,
		marginTop:5,
		marginBottom:5
	},
	centerContainer: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column"
	},
	btnContainer: {
		display: "flex",
		justifyContent: "center",
	},
	titleContainer: {
		textAlign:"center"
	},
	templateSelectionContainer: {
		display:"flex"
	},
	templateSelectionCol: {
		flex: 1
	}
}

export default Home;